<template>
  <div class="sellerfaq">
    <!-- 购买流程 -->
    <!-- <ul class="shopStep">
      <li v-for="(shopItem, index) in stepList"
          :key="index">
        <div class="shopSvg">
          <svg width="50px"
               height="50px"
               viewBox="0 0 16 16"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  :d="pathItem.pathD"
                  v-for="(pathItem, index) in shopItem.path"
                  :key="index"></path>
          </svg>
        </div>
        <div class="shopText">{{shopItem.name}}</div>
        <div class="shopImg"
             v-if="shopItem.bool">
          <img src="../../assets/img/step.png"
               alt="" />
        </div>
      </li>
    </ul> -->
    <!-- 常见问题 -->
    <div class="container">
      <h4>1. Terms Overview</h4>
      <p>By visiting the website at <a href="https://www.crediscounts.com" target="_blank">https://www.crediscounts.com</a>, you agree to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for complying with any applicable local laws.</p>
      <p>If you do not agree to any of these terms, you are prohibited from using or accessing the site. The materials contained in this website are protected by applicable copyright and trademark laws.</p>
      <h4>2. Use License</h4>
      <p>Allows temporary download of a copy of a material (information or software) from the Crediscounts website for personal, non-commercial temporary viewing only. This is licensing, not a transfer of ownership.</p>
      <h4>Under this license, you may not:</h4>
      <p>• Modify or copy the materials;</p>
      <p>• Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</p>
      <p>• Attempt to decompile or reverse engineer any software contained on Crediscounts’s website;</p>
      <p>• Remove any copyright or other proprietary notations from the materials; or</p>
      <p>• Transfer the materials to another person or “mirror” the materials on any other server.If you violate any of these restrictions, the license automatically terminates, and Crediscounts may terminate its license at any time. Upon termination of access to these materials or the termination of this license, you must destroy all downloaded materials in electronic or printed formats.</p>
      <h4>3. Disclaimer</h4>
      <p>Materials on Crediscounts are provided "as is". Crediscounts makes no warranties, express or implied, and hereby represents and disclaims all other warranties, including but not limited to implied warranties or merchantability conditions, applicable for a particular purpose or implied warranties against infringement of intellectual property or other infringement of rights.</p>
      <p>In addition, Crediscounts does not warrant or make any representation as to the accuracy, possible results or reliability of the use of such materials on or with its site or any other site linked to this site.</p>
      <h6>4. Deals policy</h6>
      <p>Transactions in Crediscounts are only available to registered users. Each user can only use one account. Crediscounts reserves the right to restrict or terminate your account if it has good reason to believe that the transaction was obtained in an unauthorized manner.</p>
      <h6>5. Llimitations</h6>
      <p>Crediscounts or its supplier shall not be liable for any loss (including but not limited to loss of data or profits or business interruption) resulting from the use or inability to use the information on the Crediscounts website, even if Crediscounts or an authorized representative of Crediscounts has been notified orally or in writing of the possibility of such damage. Since some jurisdictions do not allow limitations on implied warranties or on liability for indirect or incidental loss, these limitations may not apply to you.</p>
      <h6>6. Accuracy of Materials</h6>
      <p>The material displayed on the Crediscounts site may include technical, print or photographic errors. Crediscounts does not warrant that any material on its website is accurate, complete or up to date. Crediscounts may change the information contained on its website from time to time without notice. But Crediscounts has made no commitment to update the material.</p>
      <h4>7.Links</h4>
      <p>Crediscounts has not reviewed all sites linked to its site and is not responsible for the content of any such linked sites. The inclusion of any link does not endorse the site. Users use any such linked sites at their own risk</p>
      <h4>8. Modifications</h4>
      <p>Crediscounts may change its terms of service at any time without notice. By using this site, you agree to be bound by the current version of these terms of service</p>
      <h4>9. Business transfer</h4>
      <p>As we continue to grow the business, we may sell or purchase other businesses or services. In such transactions, customer information is usually one of the transferred business assets, but is still subject to the commitments made in any existing privacy policy (unless, of course, the customer otherwise agrees). In addition, in the event that Crediscounts's owner or almost all assets are acquired, customer information will of course be one of the assets transferred.</p>
      <!-- <el-collapse v-model="activeName"
                   accordion>
        <el-collapse-item :title="conItem.title"
                          :name="conItem.name"
                          v-for="(conItem, index) in containerList"
                          :key="index">
          <div>
            <p v-for="(pItem, index) in conItem.text"
               :key="index">{{pItem.textP}}</p>
          </div>
        </el-collapse-item>
      </el-collapse> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1'
      // 流程列表
    //   stepList: [
    //     {
    //       bool: true,
    //       name: 'Product payment release',
    //       path: [
    //         {
    //           pathD:
    //             'M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'
    //         },
    //         {
    //           pathD:
    //             'M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z'
    //         },
    //         {
    //           pathD:
    //             'M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'seller creates product information',
    //       path: [
    //         {
    //           pathD:
    //             'M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'Buyer submits request',
    //       path: [
    //         {
    //           pathD:
    //             'M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'
    //         },
    //         {
    //           pathD:
    //             'M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: false,
    //       name: 'Get the refund to your PayPal account',
    //       path: [
    //         {
    //           pathD:
    //             'M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z'
    //         },
    //         {
    //           pathD:
    //             'M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z'
    //         }
    //       ]
    //     }
    //   ]
      // 问题列表
    //   containerList: [
    //     {
    //       name: 1,
    //       title: '1.What is crediscounts?',
    //       text: [
    //         { textP: 'crediscounts is a discount platform for Amazon products,we collect the latest deals to help buyers save money. Every user can choose the products they would like to buy and purchase them on Amazon at the lowest price' }
    //       ]
    //     },
    //     {
    //       name: 2,
    //       title: '2.How do I get discounted deals on crediscounts?',
    //       text: [
    //         { textP: 'There are 4 easy steps to get any discounts on crediscounts' },
    //         { textP: 'Step 1: Create a crediscounts buyer account and complete your information.' },
    //         { textP: 'Step 2: Apply for the transaction request and contact the seller or wait for the seller’s approval.' },
    //         { textP: 'Step 3: After the seller approves your transaction request, buy the item on Amazon and upload your order information.' },
    //         { textP: 'Step 4: Seller confirms your order information and refunds the order money to your PayPal account accordingly.' }
    //       ]
    //     },
    //     {
    //       name: 3,
    //       title: '3.Why did my transaction request fail?',
    //       text: [
    //         { textP: '1.The number of product requests has reached the limit set by the seller.' },
    //         { textP: '2.Your available points are not enough to claim the deal.' }
    //       ]
    //     },
    //     {
    //       name: 4,
    //       title: '4.Which Amazon market does crediscounts support?',
    //       text: [
    //         { textP: 'Support US, UK, DE, FR, IT, CA, JP, ES, BR, IN, MX. The United States site has the most traffic, followed by Germany, the United Kingdom, Spain, and other European countries.' }
    //       ]
    //     },
    //     {
    //       name: 5,
    //       title: '5.Does crediscounts comply with the Amazon TOS Terms of Service?',
    //       text: [
    //         { textP: 'Sure, the crediscounts is fully compliant with Amazon’s platform rules. At the same time, third-party Amazon platforms are welcomed to bring in more high-quality off-site traffic to sellers’ stores and products.' }
    //       ]
    //     },
    //     {
    //       name: 6,
    //       title: '6.When will my money be transferred to my account?',
    //       text: [
    //         { textP: 'Usually, after the seller confirms that your order is valid, you will receive a refund to your PayPal account. You can check the refund status under My Deals.' }
    //       ]
    //     },
    //     {
    //       name: 7,
    //       title: '7.Will you cover the transaction fee charged by PayPal platform?',
    //       text: [
    //         { textP: 'No, we don’t pay PayPal. Besides, the customer may pay taxes and additional shipping costs on Amazon. You can negotiate with the seller about who will bear the transaction fees!' }
    //       ]
    //     },
    //     {
    //       name: 8,
    //       title: '8.What payment methods and refund methods do crediscounts support?',
    //       text: [
    //         { textP: 'We currently only support refunds for PayPal accounts. Only a small number of merchants will refund the discount volume or gift card at the request of the buyer.' }
    //       ]
    //     },
    //     {
    //       name: 9,
    //       title: '9.Do mechants have access to my information?',
    //       text: [
    //         { textP: 'Currently, we will only reveal your PayPal account and Amazon profile to sellers after you have made a purchase and uploaded your order info.' }
    //       ]
    //     },
    //     {
    //       name: 10,
    //       title: '10.Why was my account suspended?',
    //       text: [
    //         { textP: 'The reasons why we suspend your account may be as follows:' },
    //         { textP: '1.You have submitted the Amazon order and requested a refund on our site, but you still have requested a refund on Amazon.' },
    //         { textP: '2.Register multiple accounts to earn points by fraud.If your account does not have these behaviors during use, please feel free to contact us.' }
    //       ]
    //     }
    //   ]
    }
  }
}
</script>

<style lang="less" scoped>
.sellerfaq {
  text-align: left;
  background: #f2f2f2;
  padding: 30px 15px 50px 15px;
  .shopStep {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shopSvg {
        svg {
          color: #f93;
        }
      }
      .shopText {
        margin-left: 20px;
        font-size: 16px;
        line-height: 1.5;
      }
      .shopImg {
        margin-left: 40px;
      }
    }
  }
  .container {
    background: white;
    margin-top: 20px;
    h4 {
      font-size: 28px;
      padding: 26px 36px 10px 36px;
      color: #ff9933;
      font-weight: 500;
    }
    h6 {
      font-size: 22px;
      padding: 5px 36px;
      color: #ff9933;
      font-weight: 500;
      margin: 0;
    }
    p {
      font-size: 16px;
      padding: 10px 36px;
      margin: 0;
      font-weight: 100;
      line-height: 2;
      a{
        color: #409EFF;
      }
    }
    //  .el-collapse-item__header {
    //    padding-left: 36px;
    //  }
    .el-collapse {
      .el-collapse-item {
        padding-left: 36px;
        border-bottom: 1px solid #ebeef5;
        /deep/ .el-collapse-item__header {
          border-bottom: 0px;
          position: relative;
          padding-left: 15px;
          font-size: 16px;
          font-weight: 600;
          /deep/ .el-collapse-item__arrow {
            position: absolute;
            left: 0;
            top: 18px;
            //  transform: translateY(-50%);
          }
        }
        /deep/ .el-collapse-item__wrap {
          border-bottom: 0px;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
